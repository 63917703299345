/* eslint-disable */
import { stringify } from 'query-string';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import resourceUrl from './resources';
import responses from './responses';
import formatSortParameters from '../helpers/formatSortParameters';
import Auth from '@aws-amplify/auth';
import Cookies from 'js-cookie';

interface RequestOptions {
  method?: string;
  body?: string;
  headers?: any;
}

interface GetListQueryInterface {
  sort?: string;
  page?: number;
  size?: number;
  filter?: string;
}

export const GET = 'GET';
export const POST = 'POST';
export const POST_FORM_DATA = 'POST_FORM_DATA';
export const PUT = 'PUT';
export const PUT_FORM_DATA = 'PUT_FORM_DATA';

const DEFAULT_ORDER_STATUSES = ['AWAITING_PAYMENT', 'AWAITING_SHIPMENT', 'ON_HOLD'];
const BOOLEAN_RESPONSE = ['ungroupedExist', 'checkOrders', 'shipmentsAny', 'rolloPickupAvailable'];
const CONTACTS_RESOURCES = [
  'recipientsSearch',
  'contactsSearch',
  'contactsBrowse',
  'recipients',
  'contacts',
  'contactsCSV',
  'recentContacts',
  'contactUsed',
];
const PUBLIC_RESOURCES = ['helpIndex', 'help'];

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = `${apiUrl}${resourceUrl(resource)}`;

    const options: RequestOptions = {
      headers: params && params.headers,
    };
    switch (type) {
      case GET:
        if (params)
          formatSortParameters(
            resource,
            { field: params.sort, order: params[`${params.sort}.dir`] },
            params
          );
        if (params?.id) {
          url += `/${params.id}`;
          delete params.id;
        }
        url += `?${stringify(params)}`;
        break;
      case GET_LIST: {
        if (resource === 'contacts') {
          url += '/browse';
        }

        if (resource === 'inventoryProductHistory') {
          url = `${apiUrl}${resourceUrl(resource, params)}`;
        }

        if (params) {
          const query: GetListQueryInterface = {};
          const { pagination, sort, filter, id, dir } = params;

          if (resource !== 'inventoryProductHistory' && id) {
            url += `/${id}`;
          }

          if (pagination) {
            const { page, perPage } = pagination;
            query.page = page;
            query.size = perPage;
          }

          if (sort && resource !== 'contacts') {
            formatSortParameters(resource, sort, query);
          }

          url += `?${stringify(query)}`;

          if (dir && resource === 'contactsBrowse') {
            url += `&dir=${dir}`;
          }

          if (!Object.keys(filter ?? {}).length && resource === 'orders') {
            url += `&filter.${encodeURIComponent('status')}=${encodeURIComponent(
              DEFAULT_ORDER_STATUSES.join(',')
            )}`;
          }

          if (filter && resource !== 'contacts') {
            if (resource === 'shipments' && filter.account) {
              filter['account.type'] = filter.account;
              delete filter.account;
            }
            for (let key in filter) {
              const filterElement = filter[key];

              if (key === 'q') {
                url += `&q=${encodeURIComponent(filterElement)}`;
              } else if (key === 'search') {
                url += `&search=${encodeURIComponent(filterElement)}`;
              } else if (key === 'prefix') {
                url += `&prefix=${encodeURIComponent(filterElement)}`;
              } else if (key === 'ungroupedOnly') {
                url += `&ungroupedOnly=${encodeURIComponent(filterElement)}`;
              } else if (key === 'limit') {
                url += `&limit=${encodeURIComponent(filterElement)}`;
              } else if (typeof filterElement === 'string' || typeof filterElement === 'boolean') {
                url += `&filter.${encodeURIComponent(key)}=${encodeURIComponent(filterElement)}`;
              } else if (Array.isArray(filterElement)) {
                url += `&filter.${encodeURIComponent(key)}=${encodeURIComponent(
                  filterElement.join(',')
                )}`;
              } else if (filterElement instanceof Object) {
                for (let sub in filterElement) {
                  url += `&filter.${encodeURIComponent(key)}.${encodeURIComponent(
                    sub
                  )}=${encodeURIComponent(filterElement[sub])}`;
                }
              }
            }
          }
          url = url.replace('?&', '?');
        }
        break;
      }
      case GET_ONE:
        url = `${apiUrl}${resourceUrl(resource)}/${params.id}`;
        break;
      case 'GET_NEST':
        url = `${apiUrl}${resourceUrl(resource, params)}`;
        if (params?.filter) {
          url += `/?filter=${params.filter}`;
        }

        if (params?.extension) {
          url += `/?extension=${params.extension}`;
        }

        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };
        url += `?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        let query;

        if (params && params.pagination && params.sort) {
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
              ...params.filter,
              [params.target]: params.id,
            }),
          };
        }

        url = `${apiUrl}${resourceUrl(resource, params)}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url += `/${params.id}`;
        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
        break;
      case POST:
      case CREATE:
        url = `${apiUrl}${resourceUrl(resource, params)}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case POST_FORM_DATA:
        url = `${apiUrl}${resourceUrl(resource, params)}`;
        options.method = 'POST';
        options.body = params.formData;
        options.headers = {
          Accept: '',
        };
        break;
      case PUT_FORM_DATA:
        url = `${apiUrl}${resourceUrl(resource, params)}/${params.id}`;
        options.method = 'PUT';
        options.body = params.formData;
        options.headers = {
          Accept: '',
        };
        break;
      case PUT: {
        url = `${apiUrl}${resourceUrl(resource, params)}`;
        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
        break;
      }

      case DELETE: {
        url = params?.nested
          ? `${apiUrl}${resourceUrl(resource, params.nested)}/`
          : `${apiUrl}${resourceUrl(resource)}/`;

        if (params?.id) {
          url += `${params.id}`;
        }

        options.method = 'DELETE';

        if (params?.data) {
          options.body = JSON.stringify(params.data);
        }
        break;
      }

      case 'GET_IDS':
        url = `${apiUrl}${resourceUrl(resource, params)}`;
        const query: GetListQueryInterface = {};
        if (params['filter.status']) query['filter.status'] = params['filter.status'];
        if (params['filter.market']) query['filter.market'] = params['filter.market'];
        if (params['filter.date.from']) query['filter.date.from'] = params['filter.date.from'];
        if (params['filter.date.to']) query['filter.date.to'] = params['filter.date.to'];
        if (params['filter.account']) query['filter.account'] = params['filter.account'];
        if (params['filter.manual']) query['filter.manual'] = params['filter.manual'];
        url += `?${stringify(query)}`;
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json, blob, body } = response;
    const rolloAppVersion =
      headers.has('rollo-min-version-web') && headers.get('rollo-min-version-web');

    const currentRolloAppVersion = Cookies.get('rolloAppVersion');

    if (
      String(rolloAppVersion) !== currentRolloAppVersion &&
      !CONTACTS_RESOURCES.includes(resource) &&
      !PUBLIC_RESOURCES.includes(resource)
    ) {
      Cookies.set('rolloAppVersion', rolloAppVersion, { expires: 120 });
      window.location.reload();
    }

    const backendPhoneVerified = JSON.parse(headers.get('user-phone-validated'));

    if (typeof backendPhoneVerified === 'boolean') {
      sessionStorage.setItem('phoneVerified', JSON.stringify(backendPhoneVerified));
    } else if (CONTACTS_RESOURCES.includes(resource)) {
    } else {
      sessionStorage.setItem('phoneVerified', JSON.stringify(true));
    }

    switch (type) {
      case GET_LIST:
        if (resource === 'contacts') {
          return {
            data: json.contacts,
            total: parseInt(json.total_count),
          };
        } else {
          return {
            data: json,
            total: parseInt(headers.get('x-total-count')),
          };
        }
      case GET_MANY_REFERENCE:
        return {
          data: json,
          total: json.length,
        };
      case CREATE:
        return { data: { ...json, id: json?.id } };
      case DELETE_MANY: {
        return { data: json || [] };
      }
      case GET_ONE:
        return {
          data: {
            ...json,
            id: json.id || 0,
          },
          total: parseInt(headers.get('x-total-count')),
        };
      case DELETE:
        return {
          data: {
            // id: json.deleted[0]
          },
        };
      case GET:
        if (BOOLEAN_RESPONSE.includes(resource)) {
          return {
            data: json,
          };
        }
        return {
          data: json || blob || body,
          total: parseInt(headers.get('x-total-count')),
        };
      default:
        if (BOOLEAN_RESPONSE.includes(resource)) {
          return {
            data: json,
          };
        }
        return {
          data: json || blob || body,
        };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    const { url, options } = convertDataRequestToHTTP(type, resource, params);

    let responseFormatter = ({ response, url, options, httpClient, params, apiUrl }) => response;

    if (responses[resource] && responses[resource][type]) {
      responseFormatter = responses[resource][type];
    }

    return httpClient(url, options)
      .then((response) =>
        responseFormatter({
          response,
          url,
          options,
          httpClient,
          params,
          apiUrl,
        })
      )
      .then((response) => convertHTTPResponse(response, type, resource, params))
      .catch(async (error) => {
        if (window.location.pathname !== '/maintenance' && error?.status === 503) {
          window.location.replace('/maintenance');
        } else if (error?.status === 401 || error?.status === 403) {
          localStorage.removeItem('verificationClosed');
          await Auth.signOut();
          window.location.href = '/';
        } else {
          throw error;
        }
      });
  };
};
