import { lazy } from 'react';

import OAuthRedirect from 'pages/auth/OAuthRedirect';

const SignIn = lazy(() => import('./SignIn'));
const SignUp = lazy(() => import('pages/auth/SignUp'));
const ConfirmationWasSent = lazy(() => import('pages/auth/ConfirmationWasSent'));
const ConfirmSignUp = lazy(() => import('pages/auth/ConfirmSignUp'));
const RecoveryWasSent = lazy(() => import('pages/auth/RecoveryWasSent'));
const CreateNewPassword = lazy(() => import('pages/auth/CreateNewPassword'));
const ConfirmSignIn = lazy(() => import('pages/auth/ConfirmSignIn'));

export {
  ConfirmationWasSent,
  ConfirmSignUp,
  RecoveryWasSent,
  CreateNewPassword,
  SignIn,
  OAuthRedirect,
  SignUp,
  ConfirmSignIn,
};
